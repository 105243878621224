import React from 'react';

export const WheelIntro = () => (
  <>
    The philosophy of the wheel boils down to the idea that integration is essential to a well-balanced life. Integration, in this case, involves paying close attention to six areas of your life: health, self-knowledge, spirituality, work, play, and relationships. Individually, each area strengthens the overall quality of your life. When worked on and practiced in unison, each area feeds and enhances another.
  </>
);

const options = ['Relationships', 'Self-Knowledge', 'Professional Life', 'Play', 'Spirituality', 'Health'];

export const wheelSections = [
  `This part of the wheel is where we look at your marriage, your partner,  your family, your friends. The quality of the relationship with the people you love and value most is essential to a balanced life. Are you present with your children, or are you too tired when you get home from work? Do you and your partner spend enough quality time together?`,
  `A lot of us don’t admit when we are suffering or when we need help. If you are struggling with heaviness, depression, anxiety, or you find yourself struggling to even imagine a future yourself, it’s a good idea to seek counseling.`,
  `Your work is how you make your money and a massive part of how you spend your time. That’s why it is especially important that you are constantly moving in the direction of your professional purpose. Go for that promotion. Or start looking for other jobs and other opportunities if you are unhappy. Whatever you do, keep moving.`,
  `A lot of us fall too far into work and neglect the part of us that keeps us childlike, alive, happy. You have to make time to enjoy your life, cook huge dinners, go to parks, on adventures, for drives. Do whatever it is that makes you feel alive, and do it every day.  We don’t play enough. We don’t do nothing enough.`,
  `Maybe you attended church or a mosque or a synagogue; maybe you believe in one god or many. Being spiritual, though, is only about you. It has nothing to do with anyone else.`,
  `Are you breaking a sweat every day? Is your body moving enough? How’s your diet? Do you sleep well? Do you wake up feeling rested? When is the last time you got a physical? Literally every other part of the wheel is contingent on your body’s well-being. The better you feel, the better you feel. It’s as simple as that.`
];
