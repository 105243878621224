import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  &.baby {
    margin-top: 16px;
  }
`;

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  &.small {
    background: black;
    width: 100%;
    height: 100%;
  }
`;

const Video = ({ src, small = false, title, ...props }) => (
  <Container className={small ? 'baby' : ''}>
    <Iframe
      src={src}
      title={title}
      className={small ? 'small' : ''}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    /> 
  </Container>
)
export default Video;
