import React, { useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import joinImage from '../../assets/joinus.png';
import Join from '../content/join';
import { wheelSections } from '../content/wheel';

const OuterModal = styled.div`
  display: ${props => props.display ? 'flex': 'none'};
  bottom: 0;
  height: 100vh; 
  left: 0;
  top: 0;
  right: 0;
  width: 100vw;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  transition: all ease-in 0.2s;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: ${props => props.display ? 1 : 0};
  z-index: ${props => props.display ? 3 : -5};
`;

const InnerModal = styled.div`
  opacity: inherit;
  transition: all ease-in 0.2s;
  position: relative;
  width: fit-content;
  height: fit-content;
  max-height: 100vh;
  max-width: 90vw;
  margin: 0 auto;
  background: white;
  padding: 30px;
  vertical-align: center;
  display: ${(props) => props.display ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: center;
  z-index: ${props => props.display ? 3 : -5};
  @media screen and (max-width: 568px) {
    padding: 0px;
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    margin: 0;
    background-image: url(${props => props.src});
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 568px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  height: inherit;
  width: 185px;
  background-image: url(${props => props.src}), linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
  background-size: cover;
  @media screen and (min-width: 568px and max-width: 768px) {
    
  }
  @media screen and (max-width: 568px) {
    display: none;
  }
`;

const SubContainer = styled.div`
  padding: 0 0 0 20px;
  @media screen and (max-width: 568px) {
    padding: 0 20px;
  }
`;

const Title = styled.h4`
  color: ${props => props.theme.colors.important};
  font-family: ${props => props.theme.fonts.title};
  font-size: 38px;
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;

const Text = styled.p`
  margin-top: 30px;
  max-width: 500px;
  font-size: 16px;
  font-weight: 300;
  font-family: ${(props) => props.theme.fonts.excerpt};
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Form = styled.form`
  font-family: ${(props) => props.theme.fonts.excerpt};
  display: flex;
  flex-direction: row;
  input {
    padding: 5px;
    margin: 10px 0;
    width: 80%;
  }
  input:focus {
    background-color: #FDFDFD; 
  }
  @media screen and (max-width: 568px) {
    flex-direction: column;
    input {
      margin: 0;
      width: 100%;
    }
  }
`;

const Button = styled.button`
  display: inline-block;
  background: ${props => props.theme.colors.important};
  color: ${props => props.theme.colors.third};
  font-family: ${(props) => props.theme.fonts.excerpt};
  font-weight: 900;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  width: fit-content;
  margin: 10px 0 10px 5px;
  &:hover {
    cursor: pointer;
  }
`;

const Close = styled.p`
  position: absolute;
  top: 10px;
  font-size: 30px;
  font-weight: 300;
  right: 20px;
  font-family: ${(props) => props.theme.fonts.excerpt};
  &:hover {
    cursor: pointer;
  }
`;

export default ({ closeModal, display, image = joinImage }) => {
  const [form, setForm] = useState({
    EMAIL: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { EMAIL, ...fields } = form;
    const resp = await addToMailchimp(EMAIL, fields);
    closeModal();
  }

  return (
    <OuterModal display={display}>
      <InnerModal display={display} src={image}>
        <Close onClick={closeModal}>x</Close>
        <Container>
          <ImageContainer src={image}>
          </ImageContainer>
          <SubContainer>
            <Title>Join Us!</Title>
            <Text>
              <Join />
            </Text>
            <Form onSubmit={handleSubmit}>
              <input 
                name="EMAIL"
                onChange={(e) => setForm({
                  ...form, [e.target.name]: e.target.value
                })}
                placeholder="you@youremail.com"
                type="text"
              />
              <Button type="submit">JOIN</Button>
            </Form>
          </SubContainer>
        </Container>
      </InnerModal>
    </OuterModal>
  );
}
