import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import JoinModal from './JoinModal';

import logo from '../../assets/logo.png';
import hamburger from '../images/hamburger.png';
import search from '../images/search.png';

const Logo = styled.img`
  width: 70px;
  height: 70px;
  padding: 20px 10px 0 10px;
  margin: 0 10px;
  @media screen and (max-width: 768px) {
    padding: 10px 10px;
  }
`;

const Search = styled.img`
  width: 50px;
  height: 50px;
  padding: 20px 10px 0 10px;
  margin: 10px;
  @media screen and (max-width: 768px) {
    margin: 0 10px;
  }
`;

const Hamburger = styled.img`
  width: 50px;
  height: 50px;
  padding: 15px 10px;
  margin: 10px;
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const Nav = styled.nav`
  width: 100%;
  max-width: 100vw;
  position: fixed;
  display: flex;
  top: 0;
  z-index: 1;
  padding: 0px 40px;
  justify-content: center;
  transition: all 0.2s ease-in;
  background-color: rgba(0,0,0,0.7);
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    height: ${props => props.open ? '100vh' : '70px'};
  }
`;

const InnerList = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    position: absolute;
    flex-direction: column;
    left: 0;
    top: 80px;
    bottom: 0;
    right: 0;
    justify-content: center;
  }
`

const NavItem = styled.li`
  margin: 0;
  transform: skew(-10deg);
  list-style: none;
  transition: all 0.1s ease-in;
  z-index: 2;
  &:hover {
    background: red;
    a, & {
      color: #333333;
    }
  }
  a, & {
    font-family: ${(props) => props.theme.fonts.excerpt};
    font-weight: 900;
    text-decoration: none;
    text-transform: uppercase;
    color: white;
  }
  a {
    height: 100%;
  }
  &.join {
    cursor: pointer;
  }
  p {
    padding: 30px 10px 30px 10px;
    margin: 0 10px;
  }
  &.mobile-join {
    display: none;
  }
  @media screen and (max-width: 768px) {
    display: ${props => props.open ? 'block' : 'none'};
    text-align: center;
    padding: 10px;
    width: auto;
    &.mobile-join {
      display: ${props => props.open ? 'none' : 'initial'};
      cursor: pointer;
      padding: 10px 0;
    }
    p {
      padding: 15px 10px 15px 10px;
    }
  }
`;

const activeLinkStyle = {
  textDecoration: 'solid white underline',
}

const Header = ({ siteTitle }) => {
  const [open, setOpen] = useState(false);
  const [joinOpen, setJoinOpen] = useState(false);
  return (
    <Nav open={open}>
      <Hamburger src={hamburger} onClick={() => setOpen(!open)}/>
      <Link to="/"><Logo src={logo} /></Link>
      <InnerList>
        <NavItem open={open}>
          <Link activeStyle={activeLinkStyle} onClick={() => setOpen(false)} to="/"><p>Home</p></Link>
        </NavItem>
        <NavItem open={open}>
          <Link activeStyle={activeLinkStyle} onClick={() => setOpen(false)} to="/about"><p>About</p></Link>
        </NavItem>
        <NavItem open={open}>
          <Link activeStyle={activeLinkStyle} onClick={() => setOpen(false)} to="/library"><p>Library</p></Link>
        </NavItem>
        <NavItem open={open}>
          <Link activeStyle={activeLinkStyle} onClick={() => setOpen(false)} to="/explore"><p>Explore</p></Link>
        </NavItem>
        <NavItem open={open}>
          <Link activeStyle={activeLinkStyle} onClick={() => setOpen(false)} to="/class"><p>The Class</p></Link>
        </NavItem>
        <NavItem className="join" open={open} onClick={() => setJoinOpen(true)}><p>Join</p></NavItem>
      </InnerList>
      <NavItem className="join mobile-join" open={open} onClick={() => setJoinOpen(true)}><p>Join</p></NavItem>
      { /* <Search src={search} /> */ }
      <JoinModal display={joinOpen} closeModal={() => setJoinOpen(false)} />
    </Nav>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;
