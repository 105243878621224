import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import Button from './Button';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${props => props.color || 'red'};
  padding: 40px;
  br {
    display: none;
  }
  @media screen and (max-width: 768px) {
    br {
      display: block;
    }
    padding: 40px auto;
    flex-direction: column;
  }
`;

const Title = styled.h2`
  color: ${props => props.color ? 'white': 'white'};
  font-family: ${(props) => props.theme.fonts.excerpt};
  text-align: center;
  margin: 10px 75px;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    margin: 20px auto;
  }
`;

const ButtonContainer = styled.div`
  @media screen and (max-width: 768px) {
    margin: 0 auto;
  }
`;

const LearnMoreSmall = ({ buttonClass, buttonText, color, isWheel, text, handleClick = () => navigate('/#the-wheel') }) => {
  return (
    <Container color={color}>
      <Title color={color}>{!isWheel && 'Spin the Wheel.'} <br />{text || 'Find Out What\'s Next.'}</Title>
      <ButtonContainer>
        <Button className={buttonClass || 'third'} text={isWheel ? 'TAKE THE CLASS' : 'SPIN THE WHEEL'} handleClick={handleClick}/>
      </ButtonContainer>
    </Container>
  )
}

export default LearnMoreSmall;
