import React from 'react';
import styled from 'styled-components';
import logo from '../../assets/logo.png';

const Container = styled.div`
  padding: 20px;
  background: black;
  display: flex;
  justify-content: space-around;
  @media screen and (max-width: 768px) {
    padding: 20px; 
  }
`;

const Subtitle = styled.p`
  color: #666666;
  font-family: '${(props) => props.theme.fonts.excerpt}';
  text-align: center;
  margin: 20px;
`;

const Company = styled.span`
  color: white;
`;

const Logo = styled.img`
  width: 70px;
  height: 70px;
  @media screen and (max-width: 768px) {
    display: none; 
  }
`;

const BottomFooter = (props) => {
  return (
    <Container>
      <Subtitle>Copyright © 2020 Wheel of Purpose, All rights reserved. | Site Created by <Company>Mission Created</Company></Subtitle>
    </Container>
  )
};

export default BottomFooter;
