import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';

import GlobalStyle from '../styles/global';
import theme from '../styles/theme';
import BottomFooter from './BottomFooter';
import Footer from './Footer';
import Header from './header';
import './layout.css';

const SiteContainer = styled.div`
  margin-top: 0px;
`;

const HiddenIntersector = styled.div`
  width: 100vw;
  height: 1px;
  opacity: 0;
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <SiteContainer>
          <main>{children}</main>
          <Footer/>
          <BottomFooter />
          <HiddenIntersector className="hidden-intersector" />
        </SiteContainer>
        <GlobalStyle />
      </ThemeProvider>
    </>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
