import React from 'react';
import styled from 'styled-components';

import Lorem from '../content/lorem';

const Container = styled.div`
  width: ${props => props.center ? '60%' : '75%'};
  text-align: ${props => props.center ? 'center' : 'left'};
  margin: ${props => props.room ? '110px auto 128px auto' : '32px auto 128px auto'};
  padding: 0;
  @media screen and (max-width: 768px) {
    width: 90%;
    margin: ${props => props.room ? '110px auto 64px auto' : '32px auto 64px auto'};
  }
`;

const SubContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
`;

const Title = styled.h1`
  width: ${props => props.center ? '100%' : props.longboi ? '700px' : '400px'};
  text-align: ${props => props.center ? 'center' : 'left'};
  font-size: ${props => props.center ? '48px' : '64px'};
  color: #666687;
  font-weight: 200;
  @media screen and (max-width: 768px) {
    width: 250px;
    margin: 0 auto;
    text-align: center;
    font-size: 36px;
  }
`;

const RedTitle = styled.span`
  color: #FC3D2C;
`;

const Paragraph = styled.p`
  margin: 64px auto 0 auto;
  color: #333333;
  padding: 0+;
  text-align: ${props => props.center ? 'center' : 'left'};
  max-width: 1200px;
  font-family: ${(props) => props.theme.fonts.excerpt};
  font-weight: 300;
`;

const Author = styled.p`
  margin: 32px auto 0 auto;
  color: #333333;
  font-family: ${(props) => props.theme.fonts.excerpt};
  font-weight: 300;
`;

const QuoteBlock = ({ author, center = false, children, grayText,longboi, redText, room }) => {
  return (
    <Container center={center} room={room}>
      <SubContainer>
        <Title longboi={longboi} center={center}>{grayText} <RedTitle>{redText}.</RedTitle></Title>
        <Paragraph center={center}>
          {children || <Lorem />}
        </Paragraph>
        {author && <Author>- {author}</Author>}
      </SubContainer>
    </Container>
  )
}

export default QuoteBlock;
