import { createGlobalStyle } from 'styled-components';
import FiraCode from '../fonts/FiraCode.ttf';
import FjallaOne from '../fonts/FjallaOne.ttf';
import Helvetica from '../fonts/Helvetica.ttf';
import Lato from '../fonts/Lato-Regular.ttf';
import LatoBold from '../fonts/Lato-Black.ttf';
import LatoMedium from '../fonts/Lato-Bold.ttf';

export default createGlobalStyle`
  @font-face {
    font-family: 'Fira Code';
    font-weight: normal;
    src: url(${FiraCode}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'Fjalla One';
    font-weight: normal;
    src: url(${FjallaOne}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'Helvetica';
    font-weight: normal;
    src: url(${Helvetica}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato';
    font-weight: normal;
    src: url(${Lato}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato';
    font-weight: normal;
    src: url(${LatoBold}) format('truetype');
    font-style: bold;
  }
  @font-face {
    font-family: 'Lato';
    font-weight: normal;
    src: url(${LatoMedium}) format('truetype');
    font-style: medium;
  }
`;
