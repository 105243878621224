import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import logo from '../../assets/logo.png';

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #2F2F52;
  padding: 64px;
`;

const Logo = styled.img`
  width: 70px;
  height: 70px;
  margin: 0 auto;
`;

const Subheader = styled.h4`
  font-family: ${(props) => props.theme.fonts.excerpt};
  color: red;
  font-size: 16px;
  line-height: 40px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 32px auto 12px;
`;

const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  a {
    margin: 0 auto;
    color: #505174;
    text-decoration: none;
    font-family: ${(props) => props.theme.fonts.excerpt};
    text-align: center;
    transition: all 0.2s;
    border-bottom: 2px solid #2F2F52;
    box-sizing: border-box;
    width: fit-content;
  }
  a:hover {
    border-bottom: 2px solid #505174;
  }
`;

const Footer = (props) => {
  return (
    <FooterContainer>
      <Logo src={logo} />
      <Subheader>EXPLORE</Subheader> 
      <LinkList>
        <Link to='/'>Training Room</Link>
        <Link to='/library'>Library</Link>
        <Link to='/class'>The Class</Link>
      </LinkList>
    </FooterContainer>
  )
}

export default Footer;
