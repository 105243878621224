import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ButtonElement = styled.button`
  background: ${props => props.theme.colors.important};
  color: ${props => props.theme.colors.third};
  font-family: ${(props) => props.theme.fonts.excerpt};
  font-weight: 900;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  display: inline-block;
  width: fit-content;
  margin: ${props => props.space ? '20px 0 0 0' : 'initial'};
  &.secondary {
    background: ${props => props.theme.colors.third};
    color: #4d4d4d;
    text-transform: none;
    font-weight: 300;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  &.third {
    background: ${props => props.theme.colors.third};
    color: ${props => props.theme.colors.slate};
    text-transform: none;
  }
  &:hover {
    cursor: pointer;
  }
  &.long {
    width: 200px;
    @media screen and (max-width: 425px) {
      width: 150px;
    }
  }
`;

const Button = ({ className, handleClick, space = false, text }) => (
  <ButtonElement space={space} className={className} onClick={() => handleClick()}>{text}</ButtonElement>
);

Button.defaultProps = {
  className: 'important',
  handleClick: () => navigate('/#the-wheel'),
  text: 'Press me!'
}

Button.propTypes = {
  className: PropTypes.string,
  handleClick: PropTypes.func,
  text: PropTypes.string
}

export default Button;
