export default {
  colors: {
    important: "#fb3c2c",
    secondary: "#666666",
    third: "#ffffff",
    black: "#000000",
    slate: "#333333",
    lightGray: "#525252",
  },
  fonts: {
    excerpt: "Lato",
    title: "Fjalla One",
  },
}
