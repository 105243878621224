import React from 'react';
import styled from 'styled-components';

const BelowMargin = styled.div`
  height: 87px;
  width: 100%;
  background: ${props => props.theme.colors.secondary}; 
  @media screen and (max-width: 768px) {
    height: 70px;
  }
`;

export default () => (
  <BelowMargin />
)
